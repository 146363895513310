import Vue from 'vue'
import Vuex from 'vuex'

import Modules from '@/applicationSettings/Modules'

Vue.use(Vuex)

import AuthStore from 'skillbase-component/src/components/skillbase-oauth2-server/store'
import ApplicationStore from '@/applicationSettings/ApplicationStore'
import AclStore from 'skillbase-component/src/components/manage-user/store'
import CancellerPendingRequestStore from 'skillbase-component/src/lib/CancellerPendingRequest.store'
import AdminStore from 'skillbase-component/src/components/admin/store/AdminStore.js'
import MobileLayoutStore from './stores/MobileLayoutStore'

let modules = {
	auth: AuthStore,
	application: ApplicationStore,
	acl: AclStore,
	axios: CancellerPendingRequestStore,
	admin: AdminStore
}

let stores = Modules.getAllStores()
Object.keys(stores).forEach((key) => {
	modules[key] = stores[key]
})

modules['mobile-layout'] = MobileLayoutStore

export default new Vuex.Store({
	modules: modules,

	actions: {
	}
})
