import ApplicationService from "@/services/ApplicationService.js"

import {routes as SearchRoutes} from "skillbase-component/src/components/search-engine/routes/vuejs-routes"
import {routes as AFIRoutes} from "skillbase-component/src/components/ask-for-it/routes/vuejs-routes"
import {routes as GroupRoutes} from "skillbase-component/src/components/group/routes/vuejs-routes"
import {routes as NotificationRoutes} from "skillbase-component/src/components/activity/routes/vuejs-routes"
import {routes as ArticleRoutes} from "skillbase-component/src/components/article/routes/vuejs-routes"
import {routes as UserProfileRoutes} from "skillbase-component/src/components/user-profile/routes/vuejs-routes"
import {routes as ThematicRoutes} from "skillbase-component/src/components/thematic/routes/vuejs-routes"
import {routes as ErpRoutes} from 'skillbase-component/src/components/erp/routes'

import {modals as GroupModals} from "skillbase-component/src/components/group/routes/vuejs-modals"
import {modals as AFIModals} from "skillbase-component/src/components/ask-for-it/routes/vuejs-modals"
import {modals as ArticleModals} from "skillbase-component/src/components/article/routes/vuejs-modals"

import SearchEngineStore from "skillbase-component/src/components/search-engine/SearchEngineStore"
import GroupStore from "skillbase-component/src/components/group/GroupStore"
import ArticleStore from "skillbase-component/src/components/article/stores/ArticleStore"
import RequestStore from 'skillbase-component/src/components/ask-for-it/RequestStore'
import FileStore from 'skillbase-component/src/components/file/FileStore'
import ActivityStore from 'skillbase-component/src/components/activity/ActivityStore'
import UserStore from 'skillbase-component/src/components/user-profile/UserStore'
import ExternalLinkStore from 'skillbase-component/src/components/external-links/ExternalLinkStore'
import CommonStore from "skillbase-component/src/components/common/store/CommonStore"
import KPIStore from "skillbase-component/src/components/admin/components/kpi/KPIStore";
import ErpStore from 'skillbase-component/src/components/erp/stores/ErpStore'
import FeedbackStore from 'skillbase-component/src/components/feedback/stores/FeedbackStore.js'

let confModules = {
    group: {
        icon: require("@/assets/modules/group-icon.svg"),
        name: "groups",
        creationLabel: "group",
        homeRoute: '/groups/home',
        adminRoute: '/groups/home',
        createRoute: '/groups/create',
        showRoute: '/groups/show/:groupId',
        routes: GroupRoutes,
        modals: GroupModals,
        stores: GroupStore
    },
    "ask-for-it": {
        icon: require("@/assets/modules/afi-icon.svg"),
        name: "Ask for it",
        creationLabel: "Ask for it",
        homeRoute: '/afi/home',
        adminRoute: '/afi/admin',
        createRoute: '/afi/request/create',
        showRoute: '/afi/request/show/:requestId',
        routes: AFIRoutes,
        modals: AFIModals,
        stores: RequestStore
    },
    article: {
        icon: require("@/assets/modules/article-icon.svg"),
        name: "Articles",
        creationLabel: "article",
        homeRoute: '/article/home',
        adminRoute: '/article/home',
        createRoute: '/article/create',
        showRoute: '/article/show/:articleId',
        routes: ArticleRoutes,
        modals: ArticleModals,
        stores: ArticleStore,
    },
    activity: {
        name: "Activity",
        routes: NotificationRoutes,
        stores: ActivityStore
    },
    search: {
        name: "Search",
        routes: SearchRoutes,
        stores: SearchEngineStore
    },
    "user-profile": {
        name: "directory",
        icon: require("@/assets/modules/directory-icon.svg"),
        routes: UserProfileRoutes,
        homeRoute: '/annuaire/home',
        stores: UserStore
    },
    file: {
        name: "File",
        stores: FileStore,
        routes: []
    },
    "external-link": {
        name: "ExternalLink",
        routes: [],
        stores: ExternalLinkStore
    },
    common: {
        name: "Common",
        routes: [],
        stores: CommonStore
    },
    kpi: {
        name: "KPI",
        routes: [],
        stores: KPIStore
    },
    thematic: {
        name: "Thematic",
        routes: ThematicRoutes
    },
    erp: {
        name: 'Erp',
        stores: ErpStore,
        routes: ErpRoutes
    },
    feedback: {
        name: "Feedback",
        stores: FeedbackStore,
        routes: []
    }
}

class Modules {
    constructor() {
        this.loadedModules = {}
    }

    getAllStores() {
        let toReturn = {}

        Object.keys(confModules).forEach((key) => {
            let stores = confModules[key].stores
            if (stores) {
                toReturn[key] = stores
            }
        });

        return toReturn
    }

    getAllRoutes() {
        let toReturn = []

        let firstAdminRoute = null

        Object.keys(confModules).forEach((key) => {
            let routes = confModules[key].routes
            routes.forEach(route => {
                if (!route.meta) {
                    route.meta = {requiresAuth: true, menu: key, layout: 'user'}
                } else {
                    if (firstAdminRoute === null && route.meta.layout == 'admin') {
                        firstAdminRoute = route
                    }

                    if (!route.meta.requiresAuth) route.meta.requiresAuth = true
                    if (!route.meta.layout) route.meta.layout = 'user'
                }
                route.meta.menu = key

                toReturn.push(route)
            })
        });

        if (firstAdminRoute) {
            firstAdminRoute.path = '/admin'
            toReturn.push(firstAdminRoute)
        }

        return toReturn
    }

    _loadModules() {
        return new Promise((resolve, reject) => {
            ApplicationService.getModules()
                .then(modules => {
                    this.loadedModules = {}
                    modules.forEach(moduleName => {
                        if (confModules[moduleName]) {
                            this.loadedModules[moduleName] = confModules[moduleName]
                        }
                    });
                    resolve(true)
                })
                .catch(err => {
                    reject(false)
                })
        })
    }

    getModules() {
        return new Promise((resolve, reject) => {
            if (Object.keys(this.loadedModules).length == 0) {
                this._loadModules()
                    .then(() => {
                        resolve(this.loadedModules)
                    })
                    .catch(err => {
                        reject(false)
                    })
            } else {
                resolve(this.loadedModules)
            }
        })
    }
}

const modules = new Modules()

export default modules