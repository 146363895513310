import Modules from "./Modules"
import LanguageStore from "../stores/LanguageStore";

export default {

    modules: {
        language: LanguageStore
    },

    state: {
        loaded: false,
        homePageDynamicGroups: process.env.VUE_APP_HOME_PAGE_CUSTOM_GROUPS && process.env.VUE_APP_HOME_PAGE_CUSTOM_GROUPS == 'true' ? true : false,
        withWeChat: process.env.VUE_APP_HOME_PAGE_WITH_WECHAT && process.env.VUE_APP_HOME_PAGE_WITH_WECHAT == 'true' ? true : false,
    },

    mutations: {
        loading_process(state) {
            state.loaded = false
        },
        loaded(state) {
            state.loaded = true
        },
        loading_error(state) {
            state.loaded = false
        }
    },

    actions: {

        loadApp({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                commit('loading_process')

                Modules.getModules()
                    .then(() => {
                        commit('loaded')
                        resolve(true)
                    })
                    .catch(err => {
                        commit('loading_error')
                        reject(false)
                    })

                dispatch('article/loadIndexedArticles', null, { root: true })
                dispatch('ask-for-it/loadIndexedDBRequests', null, { root: true })
                dispatch('common/comment/loadIndexedDBComments', null, { root: true })
                dispatch('user-profile/loadIndexedDBUsers', null, { root: true })
                dispatch('group/loadIndexedDBGroups', null, { root: true })
                dispatch('file/loadIndexedDBFiles', null, { root: true })
            })
        }
    },

    getters: {
        appLoaded: state => !!state.loaded,
        homePageDynamicGroups: state => state.homePageDynamicGroups,
        withWeChat: state => state.withWeChat,
    }
}