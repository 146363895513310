const languageList = {
    'French': {
        label: 'Français',
        icon: require("@/assets/language/FR.svg"),
        code: 'fr'
    },
    'English': {
        label: 'English',
        icon: require("@/assets/language/UK.svg"),
        code: 'en'
    },
    'Chinese': {
        label: '中文',
        icon: require("@/assets/language/ZH.svg"),
        code: 'zh-cn'
    }
}

class Languages {
    getLanguageList() {
        return {...languageList}
    }
}

export default new Languages()