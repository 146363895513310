export default {
    namespaced: true,

    state() {
        return {
            showUserMenu: false,
            showNotificationMenu: false,
            showLanguageView: false,
            showSearchEngine: false,
            showBottomMenu: true,
            showHeader: true,
        }
    },

    mutations: {
        showUserMenu(state, show) {
            state.showUserMenu = show
        },
        showNotificationMenu(state, show) {
            state.showNotificationMenu = show
        },
        showBottomMenu(state, show) {
            state.showBottomMenu = show
        },
        showHeader(state, show) {
            state.showHeader = show
        },
        showLanguageView(state, show) {
            state.showLanguageView = show
        },
        showSearchEngine(state, show) {
            state.showSearchEngine = show
        }
    },

    getters: {
        showUserMenu: state => state.showUserMenu,
        showNotificationMenu: state => state.showNotificationMenu,
        showLanguageView: state => state.showLanguageView,
        showSearchEngine: state => state.showSearchEngine,
        showBottomMenu: state => state.showBottomMenu,
        showHeader: state => state.showHeader
    }

}