import Languages from "./../applicationSettings/Languages.js";
import UserProfileService from "skillbase-component/src/components/user-profile/services/UserProfileService.js";

const getDefaultLang = () => {
    if (navigator.language == 'zh-CN') {
        return 'zh-cn';
    } else if (navigator.language.includes('fr')) {
        return 'fr';
    } else {
        return 'en';
    }
}

export default {

    namespaced: true,

    state: {
        languageList: window.localStorage.getItem('languageList') ? JSON.parse(window.localStorage.getItem('languageList')) : {},
        userLanguage: window.localStorage.getItem('userLanguage') ? JSON.parse(window.localStorage.getItem('userLanguage')) : process.env.VUE_APP_DEFAULT_LANGUAGE
    },
    mutations: {
        setLanguageList(state, languageList) {
            state.languageList = languageList
            window.localStorage.setItem('languageList', JSON.stringify(state.languageList))
        },
        setUserLanguage(state, userLanguage) {
            state.userLanguage = userLanguage
            window.localStorage.setItem('userLanguage', JSON.stringify(state.userLanguage))
        }
    },
    actions: {
        fetchLanguageList({commit}) {
            let languageList = Languages.getLanguageList();
            commit('setLanguageList', languageList)
        },
        updateUserLanguage({commit}, userLanguage) {
            commit('setUserLanguage', userLanguage)
            return new Promise((resolve, reject) => {
                UserProfileService.setUserLanguage(userLanguage).then(response => {
                    resolve(response)
                })
            })
        },
        fetchUserLanguage({commit}) {
            return new Promise((resolve, reject) => {
                let language = ''
                UserProfileService.getUserLanguage().then(response => {
                    if (response) {
                        language = response
                    } else {
                        language = getDefaultLang()
                    }
                    commit('setUserLanguage', language)
                    resolve(response)
                })
            })

        }
    },
    getters: {
        getLanguageList: state => state.languageList,
        getUserLanguage: state => state.userLanguage
    }
}