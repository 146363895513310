<template lang="pug">
    #app.app-root.theme(
        :class="{ connected: $store.getters.isLoggedIn }",
        :key="userLanguage"
    )
        template(v-if="$store.getters.isMobile === false")
            main-header.app-header(
                v-if="$store.getters.isLoggedIn == true && $store.getters.appLoaded == true && $route.meta.layout != 'onboarding'" @showNotifications="showNotification" :hoverExpanded="hoverExpanded"
            )
            transition(name="slide-fade")
                all-notifications.notifications-panel(v-if="showNotifications" v-click-outside="hideNotification")
            user-menu.app-user-menu(
                v-if="$store.getters.isLoggedIn == true && $store.getters.appLoaded == true && $route.meta.layout != 'onboarding'",
                v-bind:class="{ expanded: expanded || hoverExpanded, shadow: expanded || hoverExpanded, 'position-absolute': positionAbsolute}",
                @mouseover.native="hoverExpanded = true",
                @mouseleave.native="hoverExpanded = false; expanded = false"
            )
        template(v-else)
            mobile-main-header(v-if="$store.getters.isLoggedIn == true && $store.getters.appLoaded == true")
            mobile-bottom-menu(v-if="$store.getters.isLoggedIn == true && $store.getters.appLoaded == true")
            mobile-user-menu(v-if="$store.getters['mobile-layout/showUserMenu']")
            mobile-notification-menu(v-if="$store.getters['mobile-layout/showNotificationMenu']")
            language-view(v-if="$store.getters['mobile-layout/showLanguageView']")
            mobile-search(v-if="$store.getters['mobile-layout/showSearchEngine']")

        router-view.app-main-wrapper(
            v-bind:class="{ full: $store.getters.isLoggedIn == false || $store.getters.isMobile == true || $route.meta.layout == 'onboarding', fixed: modalOpened == true, unfixed: modalOpened == false }",
        )
        waiting-wall.wrapper-not-ready(
            v-if="!$store.getters.appLoaded && $store.getters.isLoggedIn"
        )

        modal-launcher(@close="modalOpened = false" @open="modalOpened = true")
        snackbar-launcher
</template>

<script lang="ts">
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
    name: "App",

    components: {
        // partie PC
        MainHeader: () => import("./components/pc/Header"),
        UserMenu: () => import("./components/pc/UserMenu"),
        // partie MOBILE
        MobileMainHeader: () => import("./components/mobile/Header.vue"),
        MobileBottomMenu: () => import('./components/mobile/BottomMenu.vue'),
        MobileUserMenu: () => import('./components/mobile/BottomMenu/UserMenu.vue'),
        MobileNotificationMenu: () => import ("skillbase-component/src/components/activity/views/MobileNotificationMenu.vue"),
        LanguageView: () => import('./views/mobile/Language.vue'),
        MobileSearch: () => import('skillbase-component/src/components/search-engine/views/MobileSearch.vue'),
        AllNotifications: () => import('skillbase-component/src/components/activity/views/AllNotifications'),
        WaitingWall: () => import("./components/WaitingWall"),
        ModalLauncher: () =>
            import("skillbase-component/src/components/common/ModalLauncher"),
        SnackbarLauncher: () =>
            import(
                "skillbase-component/src/components/common/SnackbarLauncher.vue"
                ),
    },

    data() {
        return {
            expanded: false,
            hoverExpanded: false,
            mobileShowLayout: true,
            paddingLeft: 0,
            paddingRight: 0,
            positionAbsolute: false,
            modalOpened: false,
            showNotifications: false
        };
    },

    methods: {
        showNotification(value) {
            this.showNotifications = value
            this.$forceUpdate()
        },
        hideNotification(el) {
            let classList = el.srcElement.classList;
            if (classList.length && classList.contains('bell-button') === false && this.showNotifications) {
                this.showNotifications = false;
                this.$eventBus.$emit('Header:hideNotification');
            }
        }
    },

    watch: {
        userLanguage: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.$moment.locale(this.userLanguage);
                this.$i18n.locale = this.userLanguage;
            },
        },

        userLoggedIn: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue) {
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + localStorage.getItem("skbtoken");
                    this.$store.dispatch("me");
                    this.$store.dispatch("loadApp");
                    this.$store.dispatch("language/fetchUserLanguage");
                }
            },
        },
    },

    computed: {
        userLanguage() {
            return this.$store.getters["language/getUserLanguage"];
        },
        userLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
    },

    mounted() {
        this.$eventBus.$on("Header:click-menu", () => {
            this.expanded = !this.expanded;
        });

        this.$eventBus.$on("Dropdown:toggle", (show) => {
            this.mobileShowLayout = !show;
        });

        this.$eventBus.$on("Scroll:toTop", () => {
            document.querySelector(".app-main-wrapper")
                .scrollTo({top: 0, left: 0, behavior: "smooth"});
        });

        this.$store.dispatch('oidc/refreshToken')

        document.addEventListener('click', (e) => {
            if (e.target && e.target.nodeName && e.target.nodeName.toLowerCase() == 'a') {
                e.preventDefault()
                e.stopPropagation()
                let url = e.target.getAttribute('href')
                let blank = e.target.getAttribute('target')
                if (url) {
                    window.open(url, '_blank')
                }
            }
        })
    },
});
</script>

<style lang="scss" scoped>
#app {
  $headerHeight: 70px;
  $userMenuWidth: 80px;
  $userMenuExpandedWidth: 191px;
  overflow: hidden;
  background-color: var(--background-color);
  min-width: 1280px;
  height: 100vh;

  .app-main-wrapper {
    height: calc(100vh - 70px);
    &.full {
      height: 100vh !important;
      margin-top: 0 !important;
    }
  }

  .notifications-panel {
    position: fixed;
    top: 70px;
    right: 0;
    background-color: white;
    height: calc(100vh - 68px);
    z-index: 3003;
  }

  &.connected {
    .wrapper-not-ready {
      position: fixed;
      top: 0px;
      left: 0px;
      height: 100vh;
      width: 100vw;
      z-index: 3002;
      background-color: white;
    }

    .app-header {
      position: fixed;
      top: 0px;
      height: $headerHeight;
      z-index: 4000;
    }

    .app-user-menu {
      position: fixed;
      left: 0px;
      top: $headerHeight;
      width: $userMenuWidth;
      height: calc(100vh - #{$headerHeight});
      z-index: 3004;
      transition: width 0.2s;
      overflow: hidden;

      &.position-absolute {
        position: absolute;
      }

      &.expanded {
        width: $userMenuExpandedWidth;
      }

      &.shadow {
        overflow: hidden;
        box-shadow: 0px 5px 5px var(--shadow-color);
      }
    }

    .hide {
      z-index: -1 !important;
    }

    .app-main-wrapper {
      width: calc(100% - #{$userMenuWidth});
      margin-right: 0px;
      overflow-y: scroll;
      margin-left: $userMenuWidth;
      box-sizing: border-box;
      margin-top: 70px;

      &.full {
        width: 100vw;
        height: 100vh;
        margin-left: 0px;
        position: fixed;
      }

    }

    .fixed {
      position: fixed;
    }

    .unfixed {
      position: relative;
    }
  }

  @media only screen and (max-width: 700px) {
    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;
    position: relative;

    &.connected {
      overflow-x: inherit;

      .app-main-wrapper {
        overflow-x: hidden;
        left: 0px;
        right: 0px;
        width: 100vw;
        padding-bottom: 56px;
        box-sizing: border-box;
        background-color: var(--background-color);
        min-width: 100vw;
        margin-top: 0;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .app-main-wrapper {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
</style>
