import Vue from 'vue';
import VueI18n from 'vue-i18n';
import traduction from './traduction.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'fr',
    messages: traduction,
    silentTranslationWarn: true,
});

/**
 * Set language.
 * @param lang
 */
export default i18n;
